export const financesRadioValuesToLabel = (value) => {
    switch (value) {
        case '10000':
            return '0 - 10.000 Euro';
        case '25000':
            return '10.000 - 25.000 Euro';
        case '50000':
            return '25.000 - 50.000 Euro';
        case '100000':
            return '50.000 - 100.000 Euro';
        case '500000':
            return '100.000 - 500.000 Euro';
        case '>500000':
            return 'Mehr als 500.000 Euro';
    }
};

export const numberToEuro = (value) => {
    value = parseInt(value, 10);
    return new Intl.NumberFormat('de-DE',
        { style: 'currency', currency: 'EUR', }
    ).format(value);
};
