import * as React from 'react';

// @ts-ignore
import * as styles from './Divider.module.css';

const Divider = () => {
    return <hr className={styles.hr}/>;
};

export default Divider;
