// extracted by mini-css-extract-plugin
export var slider = "Slider-module--slider--2Mx1S";
export var sliderRail = "Slider-module--sliderRail--1sbx6";
export var swipe = "Slider-module--swipe--25b1r";
export var sliderItem = "Slider-module--sliderItem--2hF5L";
export var sliderItemActive = "Slider-module--sliderItemActive--zw1eJ";
export var sliderControl = "Slider-module--sliderControl--2tgxL";
export var disabled = "Slider-module--disabled--1YVHz";
export var prev = "Slider-module--prev--cKbD2";
export var next = "Slider-module--next--3MxOf";
export var sliderNavigation = "Slider-module--sliderNavigation--IkM0V";
export var sliderNavigationItem = "Slider-module--sliderNavigationItem--1hwHl";
export var sliderNavigationItemActive = "Slider-module--sliderNavigationItemActive--1NfAg";