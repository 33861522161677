import * as React from 'react';
// @ts-ignore
import * as styles from './ColumnParagraph.module.css';

interface IColumnParagraph {
    colLeftSpan?: string,
    spanClass: string,
    children: React.ReactNode;
}

const ColumnParagraph = ({
    colLeftSpan,
    spanClass,
    children,
}:IColumnParagraph ) => {
    return <div className={styles.columnParagraph__container}>
        {/* Column Left */}
        {
            colLeftSpan &&
            <div className={styles.columnParagraph__columnLeft}>
                <span className={styles[spanClass]}>{colLeftSpan}</span>
            </div>
        }
        {/* Column Right */}
        <div className={styles.columnParagraph__columnRight}>
            { children }
        </div>
    </div>;
};

export default ColumnParagraph;
