import * as React from 'react';
import BlackBox from '../BlackBox/BlackBox';
// @ts-ignore
import * as styles from './DesktopVotingRow.module.css';
import {createIntegerRange} from '../../helpers/numbers';
import {apiPostAuthenticated} from '../../api/api';
import {connect} from 'react-redux';

const DesktopVotingRow = ({category, application, storedVoting, setJuryVotings,}) => {
    return <ul className={styles.desktopVotingRow__container}>
        {
            createIntegerRange(1, 10).map(voting => {
                return  <li>
                    <BlackBox
                        selected={voting === storedVoting}
                        onClick={() => {
                            (async () => {
                                const result = await apiPostAuthenticated(
                                    `/jury/vote/${application.uuid}`,
                                    {
                                        category,
                                        voting,
                                    }
                                );
                                setJuryVotings({
                                    juryVotings: result.votings,
                                });
                            })();
                        }}>
                        <span>{voting}</span>
                    </BlackBox>
                </li>;
            })
        }
    </ul>;
};


const mapDispatchToProps = (dispatch) => {
    return {
        setJuryVotings: ({
            juryVotings,
        }) => {
            return dispatch({
                type: 'SET_JURY_VOTINGS',
                payload: {
                    juryVotings,
                },
            });
        },
    };
};

export default connect(
    null,
    mapDispatchToProps
)(DesktopVotingRow);
