import * as React from 'react';
import {useEffect, useState} from 'react';
import DefaultPage from '../../components/layouts/DefaultPage';
// @ts-ignore
import * as styles from './detail.module.css';
import Headline from '../../components/Headline/Headline';
import CustomButton from '../../components/CustomButton/CustomButton';
import CustomLink from '../../components/CustomLink/CustomLink';
import Divider from '../../components/Divider/Divider';
import ColumnParagraph from '../../components/ColumnParagraph/ColumnParagraph';
import SubtitleAndParagraph from '../../components/SubtitleAndParagraph/SubtitleAndParagraph';
import VoteBar, {votingCategories} from '../../components/VoteBar/VoteBar';
// import BackToTheTopIcon from '../../components/BackToTheTopIcon/BackToTheTopIcon';
import Slider from '../../components/SliderComponent/Slider';
import {fetchTopApplications} from '../../api/application';
import {apiDomain} from '../../api/api';
import {formatDateFromString} from '../../helpers/date';
import {salutationKeyToLabel} from '../../helpers/salutation';
import {createIntegerRange} from '../../helpers/numbers';
import {booleanToLabel} from '../../helpers/boolean';
import {financesRadioValuesToLabel, numberToEuro} from '../../helpers/labels';
import {sanitizeUrl} from '../../helpers/url';
import {getAuthUser} from '../../helpers/user';
import {navigate} from 'gatsby';
import StickyNav from '../../components/StickyNav/StickyNav';
import {connect} from 'react-redux';

const index = ({juryVotings,}) => {
    const [topApplications, setTopApplications,] = useState([]);
    const [application, setApplication,] = useState(null);

    const user = getAuthUser();
    useEffect(() => {
        if (!user) {
            navigate('/jury/login');
        } else if (!user.accepted_welcome_privacy) {
            navigate('/jury/welcome');
        }
    }, [user,]);

    useEffect(
        () => {
            (async () => {
                const result = await fetchTopApplications();
                if (result.success) {
                    const topApplications = result.applications.map(application => {
                        return {
                            ...application,
                            votings: juryVotings.filter(v => {
                                return v.applicationUuid === application.uuid;
                            }),
                        };
                    });
                    setTopApplications(topApplications);
                }
            })();
        },
        []
    );

    useEffect(
        () => {
            const application = topApplications.find(a => {
                return a.uuid === window.location.hash.replace(
                    '#',
                    ''
                );
            });
            setApplication(application);
            // scroll back to top if hash changes
            window.scrollTo(0, 0);
        },
        ['undefined' !== typeof window && window.location.hash, topApplications,]
    );

    const [renderItems, setRenderItems,] = useState([]);

    useEffect(() => {
        console.log('DEBUGAPP', application);
        if (!application) {
            return;
        }
        setRenderItems([
            <div key={1}><img
                src={`${apiDomain}/image/1000x500?path=${application.data.documentsProductPhotos.path}`}
                alt=""/>
            </div>,
            <div key={2}><img
                src={`${apiDomain}/image/1000x500?path=${application.data.documentsFoundationTeamPhotos.path}`}
                alt=""/>
            </div>,
            <div key={3}><img
                src={`${apiDomain}/image/1000x500?path=${application.data.documentsLogo.path}`}
                alt=""/>
            </div>,
        ]);
    }, [application,]);

    if (!application) {
        return null;
    }

    return <DefaultPage
        phase={process.env.GATSBY_PHASE}
        hasVotingBar>
        {/* Container */}
        <div
            id="top"
            className={`grid-2--mobile grid-8--tablet grid-12--desktop ${styles.container}`}>
            {/*<BackToTheTopIcon  />*/}

            {/* Headline */}
            <div className={styles.headline}>
                <Headline headline={application.data.innovationName}/>
            </div>
            {/* Logo */}
            <div className={styles.logo}>
                <img
                    src={`${apiDomain}/image/400x300?path=${application.data.documentsLogo.path}`}
                    alt=""/>
            </div>

            {/* Button */}
            {/*<div className={styles.button}>*/}
            {/*    <CustomButton label="Bewerbung als PDF herunterladen" />*/}
            {/*</div>*/}

            {/* Links list */}
            <ul className={styles.linksList}>
                <li className={styles.listLinksItems}>
                    <CustomLink
                        classNames="customLink__underline"
                        onClick={() => {
                            window.scrollTo({
                                top: document.getElementById('unternehmen').offsetTop,
                                behavior: 'smooth',
                            });
                        }}
                        label="Unternehmen"
                    />
                </li>
                <li className={styles.listLinksItems}>
                    <CustomLink
                        classNames="customLink__underline"
                        onClick={() => {
                            window.scrollTo({
                                top: document.getElementById('innovation').offsetTop,
                                behavior: 'smooth',
                            });
                        }}
                        label="Innovation"
                    />
                </li>
                <li className={styles.listLinksItems}>
                    <CustomLink
                        classNames="customLink__underline"
                        onClick={() => {
                            window.scrollTo({
                                top: document.getElementById('kapital').offsetTop,
                                behavior: 'smooth',
                            });
                        }}
                        label="Kapital"
                    />
                </li>
                <li className={styles.listLinksItems}>
                    <CustomLink
                        classNames="customLink__underline"
                        onClick={() => {
                            window.scrollTo({
                                top: document.getElementById('potential').offsetTop,
                                behavior: 'smooth',
                            });
                        }}
                        label="Potential"
                    />
                </li>
                <li className={styles.listLinksItems}>
                    <CustomLink
                        classNames="customLink__underline"
                        onClick={() => {
                            window.scrollTo({
                                top: document.getElementById('dokumente').offsetTop,
                                behavior: 'smooth',
                            });
                        }}
                        label="Dokumente"
                    />
                </li>
                <li className={styles.listLinksItems}>
                    <CustomLink
                        classNames="customLink__underline"
                        onClick={() => {
                            window.scrollTo({
                                top: document.getElementById('galerie').offsetTop,
                                behavior: 'smooth',
                            });
                        }}
                        label="Galerie"
                    />
                </li>
            </ul>

            {/* Content */}
            <div
                id="unternehmen"
                className={`${styles.content}`}>
                {/* Unternehmen */}
                {/* Content Headline */}
                <h4 className={styles.contentHeadline}>1. Unternehmen</h4>

                {/* Company Name */}
                <ColumnParagraph
                    spanClass="fontStyle__italic"
                    colLeftSpan="Unternehmen"
                >
                    <span>{application.data.companyName}</span>
                </ColumnParagraph>

                {/* Establishment date */}
                <ColumnParagraph
                    spanClass="fontStyle__italic"
                    colLeftSpan="Gründungsdatum:"
                >
                    <span>{formatDateFromString(application.data.companyFoundationDate)}</span>
                </ColumnParagraph>

                {/* Company address */}
                <ColumnParagraph
                    spanClass="fontStyle__italic"
                    colLeftSpan="Unternehmensadresse:"
                >
                    <span>{application.data.companyStreet___1} {application.data.companyHouseNumber___1}</span>
                    <span>{application.data.companyZipCode___1} {application.data.companyCity___1}</span>
                    <span>{application.data.companyCountry___1}</span>
                </ColumnParagraph>

                {/* Company phone number */}
                {/*<ColumnParagraph*/}
                {/*    spanClass="fontStyle__italic"*/}
                {/*    colLeftSpan="Unternehmenstelefonnummer:"*/}
                {/*>*/}
                {/*    <span>{application.data.contactPhone___1}</span>*/}
                {/*</ColumnParagraph>*/}

                {/* Founding team */}
                <ColumnParagraph
                    spanClass="fontStyle__italic"
                    colLeftSpan="Gründungsteam:"
                >
                    <ul>
                        {
                            createIntegerRange(1, 50).map(i => {
                                if (application.data[`companyFoundationTeamMemberSalutation___${i}`]) {
                                    return <li>
                                        <span>{salutationKeyToLabel(application.data[`companyFoundationTeamMemberSalutation___${i}`])} {application.data[`companyFoundationTeamMemberFirstName___${i}`]} {application.data[`companyFoundationTeamMemberLastName___${i}`]}</span>
                                        <br/>
                                        <span>{application.data[`companyFoundationTeamMemberRoles___${i}`]}</span>
                                    </li>;
                                }
                                return null;
                            })
                        }
                    </ul>
                </ColumnParagraph>

                {/* Management team */}
                {
                    application.data['companyManagementTeamMemberSalutation___1'] &&
                    <ColumnParagraph
                        spanClass="fontStyle__italic"
                        colLeftSpan="Managementteam:"
                    >
                        <ul>
                            {
                                createIntegerRange(1, 50).map(i => {
                                    if (application.data[`companyManagementTeamMemberSalutation___${i}`]) {
                                        return <li>
                                            <span>{salutationKeyToLabel(application.data[`companyManagementTeamMemberSalutation___${i}`])} {application.data[`companyManagementTeamMemberFirstName___${i}`]} {application.data[`companyManagementTeamMemberLastName___${i}`]}</span>
                                            <br/>
                                            <span>{application.data[`companyManagementTeamMemberRoles___${i}`]}</span>
                                        </li>;
                                    }
                                    return null;
                                })
                            }
                        </ul>
                    </ColumnParagraph>
                }


                {/* Number of employees */}
                <ColumnParagraph
                    spanClass="fontStyle__italic"
                    colLeftSpan="Mitarbeiteranzahl:"
                >
                    <span>{application.data.companyNumberOfEmployees}</span>
                </ColumnParagraph>

                {/* Company Website */}
                <ColumnParagraph
                    spanClass="fontStyle__italic"
                    colLeftSpan="Unternehmenswebseite:"
                >
                    <span>
                        <a
                            target="_blank"
                            rel="noopener nofollow"
                            href={sanitizeUrl(application.data.companyWebsite___1)}>
                            {application.data.companyWebsite___1}
                        </a>
                    </span>
                </ColumnParagraph>

                {/* Product website */}
                {
                    application.data.productWebsite___1 &&
                    <ColumnParagraph
                        spanClass="fontStyle__italic"
                        colLeftSpan="Produktwebseite"
                    >
                        <a
                            target="_blank"
                            rel="noopener nofollow"
                            href={sanitizeUrl(application.data.productWebsite___1)}>
                            {application.data.productWebsite___1}
                        </a>
                    </ColumnParagraph>
                }

                <Divider/>

                {/* Innovation */}
                <h4
                    id="innovation"
                    className={styles.contentHeadline}>2. Innovation
                </h4>

                {/* Brief description of the product / service */}
                <SubtitleAndParagraph
                    span="Kurzbeschreibung des Produktes / der Dienstleistung:"
                    paragraph={application.data.innovationShortDescription}
                />

                {/* Detailed description of the product / service */}
                <SubtitleAndParagraph
                    span="Ausführliche Beschreibung des Produktes / der Dienstleistung:"
                    paragraph={application.data.innovationDescription}
                />

                {/* Product / DL patent / IP protected */}
                <SubtitleAndParagraph
                    span="Produkt / DL Patent / IP-geschützt:"
                    paragraph={booleanToLabel(application.data.innovationProtected)}
                />
                {
                    application.data.innovationProtectionDescription &&
                    <SubtitleAndParagraph
                        span="Kurzbeschreibung Patent:"
                        paragraph={application.data.innovationProtectionDescription}
                    />
                }

                {/* Customer benefits */}
                <SubtitleAndParagraph
                    span="Kundennutzen:"
                    paragraph={application.data.innovationAdvantages}
                />

                {/* Unique selling point */}
                <SubtitleAndParagraph
                    span="Alleinstellungsmerkmal:"
                    paragraph={application.data.innovationUSP}
                />

                <Divider/>

                {/* Kapital */}
                <h4
                    id="kapital"
                    className={styles.contentHeadline}>3. Kapital
                </h4>

                {/* Receive funding */}
                <ColumnParagraph
                    spanClass="fontStyle__italic"
                    colLeftSpan="Finanzierung erhalten"
                >
                    <span>{booleanToLabel(application.data.financesFundingReceived)}</span>
                </ColumnParagraph>

                {
                    'n' !== application.data.financesFundingReceived &&
                    <>
                        {/* Umfang/Scope */}
                        <ColumnParagraph
                            spanClass="fontStyle__italic"
                            colLeftSpan="Umfang:"
                        >
                            <span>{financesRadioValuesToLabel(application.data.financesFundingAmount)}</span>
                        </ColumnParagraph>

                        {/* Financing Provider */}
                        <ColumnParagraph
                            spanClass="fontStyle__italic"
                            colLeftSpan="Finanzierungsgeber:"
                        >
                            <span>{application.data.financesFundingFinancier}</span>
                        </ColumnParagraph>
                    </>
                }

                {/* Planned sales level 2021 */}
                <ColumnParagraph
                    spanClass="fontStyle__italic"
                    colLeftSpan="Geplantes Umsatzlevel 2021:"
                >
                    <span>{numberToEuro(application.data.financesTurnover2021)}</span>
                </ColumnParagraph>

                {/* Planned sales level 2022 */}
                <ColumnParagraph
                    spanClass="fontStyle__italic"
                    colLeftSpan="Geplantes Umsatzlevel 2022:"
                >
                    <span>{numberToEuro(application.data.financesTurnover2022)}</span>
                </ColumnParagraph>

                {/* Planned sales level 2023 */}
                <ColumnParagraph
                    spanClass="fontStyle__italic"
                    colLeftSpan="Geplantes Umsatzlevel 2023:"
                >
                    <span>{numberToEuro(application.data.financesTurnover2023)}</span>
                </ColumnParagraph>

                <Divider/>

                {/* Potential */}
                <h4
                    id="potential"
                    className={styles.contentHeadline}>4. Potential
                </h4>

                {/* Number of customers rounded */}
                <ColumnParagraph
                    spanClass="fontStyle__italic"
                    colLeftSpan="Kundenanzahl gerundet:"
                >
                    <span>{application.data.potentialCustomersCount}</span>
                </ColumnParagraph>

                {/* Market potential */}
                <SubtitleAndParagraph
                    span="Marktpotential"
                    paragraph={application.data.potentialMarketPotential}
                />

                {/* Competition */}
                <SubtitleAndParagraph
                    span="Wettbewerber:"
                    paragraph={application.data.potentialCompetition}
                />

                <Divider/>

                {/* Dokumente */}
                <h4
                    id="dokumente"
                    className={styles.contentHeadline}>5. Dokumente
                </h4>
                <ColumnParagraph
                    spanClass="fontStyle__italic"
                >
                    <div
                        className="grid-4--desktop"
                    >
                        <CustomButton
                            // onClick={() => {
                            //     apiDisplayPdfAuthenticated(`/pdf/${application.data.documentsPitchDeck.path}`);
                            isLink
                            to={`${apiDomain}/api/pdf/${application.data.documentsPitchDeck.path}`}
                            target="_blank"
                            label="Pitchdeck ansehen"/>
                    </div>
                </ColumnParagraph>

                <Divider/>

                {/* Galerie */}
                <h4
                    id="galerie"
                    className={styles.contentHeadline}>6. Galerie
                </h4>
                {/* Slider */}
                <Slider
                    items={renderItems}
                    desktopItemsPerSlide={1}
                    mobileItemsPerSlide={1}
                    rotate={true}
                    swipe={true}
                    transitionDuration={200}
                    iconClass="black"
                    className={styles.slider}
                />

                {/* Detail Button */}
                <div className={`${styles.button}`}>
                    <CustomButton
                        isLink
                        to="/jury"
                        label={`Zurück zu den Top ${topApplications.length}`}/>
                </div>
            </div>

            <StickyNav
                current={application}
                all={topApplications.filter(application => {
                    const votings = application.votings;
                    let status = 3;
                    if (0 < votings.length && votings.length < votingCategories.length) {
                        status = 2;
                    }
                    if (votings.length === votingCategories.length) {
                        status = 1;
                    }
                    const filtered = localStorage.getItem('@focus//jury-filter') ? JSON.parse(localStorage.getItem('@focus//jury-filter')) : [1, 2, 3,];
                    return filtered.includes(status);
                })}
            />

            <VoteBar
                text="Ihr Voting"
                application={application}/>
        </div>
    </DefaultPage>;
};


const mapStateToProps = ({
    juryVotings,
}) => {
    return {
        juryVotings,
    };
};

export default connect(mapStateToProps, null)(index);
