import * as React from 'react';
// @ts-ignore
import * as styles from './BlackBox.module.css';
import {classNames} from '../../helpers/classes';

interface IBlackBox {
    children: React.ReactNode,
    onClick: any;
    selected: boolean;
}

const BlackBox = ({
    children,
    onClick,
    selected,
}: IBlackBox) => {
    return <div
        onClick={onClick}
        className={classNames({
            [styles.blackBox]: true,
            [styles.selected]: selected,
        })} >
        { children }
    </div>;
};

export default BlackBox;
