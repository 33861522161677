import * as React from 'react';
import {Link} from 'gatsby';

// @ts-ignore
import * as styles from './StickyNav.module.css';

const StickyNav = ({current, all,}) => {
    let next, prev;
    for (let i = 0; i < all.length; i++) {
        const application = all[i];
        if (application.uuid === current.uuid) {
            if (0 < i) {
                prev = all[i - 1];
            } else {
                prev = all[all.length - 1];
            }
            if (i < all.length - 1) {
                next = all[i + 1];
            } else {
                next = all[0];
            }

            break;
        }
    }

    if (!next || !prev) {
        return null;
    }

    return <>
        <nav className={styles.container}>
            <Link to="/jury">Zur Übersicht</Link>
            <Link to={`/jury/detail#${next.uuid}`}>Nächste Innovation</Link>
            <Link to={`/jury/detail#${prev.uuid}`}>Vorherige Innovation</Link>
        </nav>
        <nav className={styles.containerTabletLeft}>
            <Link to={`/jury/detail#${next.uuid}`}>Nächste Innovation</Link>
        </nav>
        <nav className={styles.containerTabletBack}>
            <Link to="/jury">Zur Übersicht</Link>
        </nav>
        <nav className={styles.containerTabletRight}>
            <Link to={`/jury/detail#${prev.uuid}`}>Vorherige Innovation</Link>
        </nav>
        <nav className={styles.containerMobileLeft}>
            <Link to={`/jury/detail#${next.uuid}`}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15.874"
                    height="9.802"
                    viewBox="0 0 15.874 9.802">
                    <path
                        id="Path_534"
                        data-name="Path 534"
                        d="M7.865,8.59l6.072,6.059L20.009,8.59l1.865,1.865-7.937,7.937L6,10.455Z"
                        transform="translate(-6 -8.59)"
                        fill="#fff"/>
                </svg>
            </Link>
        </nav>
        <nav className={styles.containerMobileRight}>
            <Link to={`/jury/detail#${prev.uuid}`}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15.874"
                    height="9.802"
                    viewBox="0 0 15.874 9.802">
                    <path
                        id="Path_534"
                        data-name="Path 534"
                        d="M7.865,8.59l6.072,6.059L20.009,8.59l1.865,1.865-7.937,7.937L6,10.455Z"
                        transform="translate(-6 -8.59)"
                        fill="#fff"/>
                </svg>
            </Link>
        </nav>
    </>;
};

export default StickyNav;
