import {
    apiGetAuthenticated, apiPostAuthenticated, apiUploadFileAuthenticated
} from './api';

export const createApplication = async () => {
    const result = await apiPostAuthenticated('/application/create');
    return {
        success: !!result.success,
        application: result.application,
        message: result.message,
    };
};

export const fetchApplication = async () => {
    const result = await apiGetAuthenticated('/application');
    return {
        success: !!result.success,
        application: result.application,
    };
};

export const fetchTopApplications = async () => {
    return await apiGetAuthenticated('/applications/top');
};

export const updateApplicationValue = async (
    name, value
) => {
    const result = await apiPostAuthenticated(
        '/application/value',
        {
            name,
            value,
        }
    );
    return {
        success: !!result.success,
        application: result.application,
    };
};

export const uploadFile = async (
    fieldName, file
) => {
    try {
        return await apiUploadFileAuthenticated(
            `/application/upload/${fieldName}`,
            fieldName,
            file
        );
    } catch (e) {
        console.error(e);
        return {
            success: false,
            message: 'Beim Hochladen der Datei ist ein Fehler aufgetreten',
        };
    }
};

export const deleteFile = async (
    fieldName: string, filePath: string
) => {
    try {
        return await apiPostAuthenticated(`/application/file/delete/${fieldName}/${filePath.split('/').pop()}`);
    } catch (e) {
        console.error(e);
        return {
            success: false,
            message: 'Beim Löschen der Datei ist ein Fehler aufgetreten',
        };
    }
};

export const submitApplication = async () => {
    return await apiPostAuthenticated('/application/submit');
};

export const destroyAccount = async () => {
    return await apiPostAuthenticated('/destroy/njDujkSU123kSD');
};
