import * as React from 'react';
import {
    useEffect, useState
} from 'react';
// @ts-ignore
import {
    useSwipeable
} from 'react-swipeable';

// @ts-ignore
import * as styles from './Slider.module.css';

interface ISlider {
    items: JSX.Element[];
    desktopItemsPerSlide?: number;
    tabletItemsPerSlide?: number;
    mobileItemsPerSlide?: number;
    transitionDuration?: number;
    rotate: boolean;
    swipe?: boolean;
    iconClass?: 'white' | 'black';
    className?: string;
    breakpoints?: {
        mobile: number;
        tablet: number;
    };
    debug?: boolean;
}

const Slider = ({
    items,
    desktopItemsPerSlide = 3,
    tabletItemsPerSlide = 3,
    mobileItemsPerSlide = 1,
    transitionDuration = 500,
    rotate = false,
    swipe = true,
    iconClass = 'black',
    className = '',
    breakpoints = {
        mobile: 720,
        tablet: 1136,
    },
    debug = false,
}: ISlider) => {
    const responsive = mobileItemsPerSlide !== desktopItemsPerSlide;

    const [sliderItems, setSliderItems,] = useState([] as JSX.Element[]);
    const [sliderNavigationItems, setSliderNavigationItems,] = useState([] as number[]);
    const [sliderPositionIndex, setSliderPositionIndex,] = useState(0);
    const [itemsPerSlide, setItemsPerSlide,] = useState(desktopItemsPerSlide);
    const [displayNavigation, setDisplayNavigation,] = useState(false);

    /**
     * Calculate slider position index by direction
     *
     * @param {-1|1} direction
     * @returns {int}
     */
    const calcSliderPositionIndex = (direction: -1 | 1) => {
        const theoreticalTargetIndex = sliderPositionIndex + direction;
        switch (rotate) {
            case true:
                return 0 > theoreticalTargetIndex
                    ? sliderItems.length - 1
                    : theoreticalTargetIndex >= sliderItems.length
                        ? 0
                        : theoreticalTargetIndex;
            case false:
                return Math.min(
                    sliderItems.length - 1,
                    Math.max(
                        0,
                        theoreticalTargetIndex
                    )
                );
        }
    };

    /**
     * Distribute slider items
     */
    const distributeSliderItems = () => {
        const distributedItems = [] as number[];
        const newSliderItems = [];
        for (const item of items) {
            const index = items.indexOf(item);
            if (0 === (index + 1) % itemsPerSlide || items.length === index + 1) {
                const newSliderItem = (
                    <>
                        {items.map((
                            item, i
                        ) => {
                            if (i > index - itemsPerSlide && i <= index && 0 > distributedItems.indexOf(i)) {
                                distributedItems.push(i);
                                return item;
                            }
                        })}
                    </>
                );
                newSliderItems.push(newSliderItem);
            }
        }
        setSliderItems(newSliderItems);
        debug && console.log(
            '[Slider]',
            'distributeSliderItems',
            newSliderItems
        );
    };

    const swipeConfiguration = {
        delta: 10,
        preventDefaultTouchmoveEvent: true,
        trackTouch: true,
        trackMouse: true,
        rotationAngle: 0,
    };

    const swipeHandlers = useSwipeable({
        onSwipedLeft: () => {
            setSliderPositionIndex(calcSliderPositionIndex(1));
        },
        onSwipedRight: () => {
            setSliderPositionIndex(calcSliderPositionIndex(-1));
        },
        ...swipeConfiguration,
    });

    /**
     * Update items per slide based on responsiveness
     */
    // eslint-disable-next-line no-unused-vars
    const updateItemsPerSlide = () => {
        if (!responsive) {
            return;
        }
        if (breakpoints.mobile > window.innerWidth) {
            debug && console.log(
                '[Slider]',
                'itemsPerSlide',
                mobileItemsPerSlide
            );
            setItemsPerSlide(mobileItemsPerSlide);
        } else if (breakpoints.mobile <= window.innerWidth && breakpoints.tablet > window.innerWidth) {
            debug && console.log(
                '[Slider]',
                'itemsPerSlide',
                tabletItemsPerSlide
            );
            setItemsPerSlide(tabletItemsPerSlide);
        } else if (breakpoints.tablet <= window.innerWidth) {
            debug && console.log(
                '[Slider]',
                'itemsPerSlide',
                desktopItemsPerSlide
            );
            setItemsPerSlide(desktopItemsPerSlide);
        }
    };

    /**
     * Update slider navigation items based on slider items length
     */
    const updateSliderNavigationItems = () => {
        let navItems = [];
        for (let i: number = 0; sliderItems.length > i; i++) {
            navItems.push(i);
        }
        setSliderNavigationItems(navItems);
        debug && console.log(
            '[Slider]',
            'updateSliderNavigationItems',
            navItems
        );
    };

    // useEffect(
    //     () => {
    //         window.addEventListener(
    //             'resize',
    //             () => {
    //                 clearTimeout(throttler);
    //                 throttler = setTimeout(
    //                     () => {
    //                         updateItemsPerSlide();
    //                         distributeSliderItems();
    //                         updateSliderNavigationItems();
    //                     },
    //                     250
    //                 );
    //             }
    //         );
    //     },
    //     []
    // );

    useEffect(
        () => {
            distributeSliderItems();
        },
        [items, itemsPerSlide,]
    );

    useEffect(
        () => {
            updateSliderNavigationItems();
        },
        [items, sliderItems,]
    );

    useEffect(
        () => {
            updateSliderNavigationItems();
            setDisplayNavigation(1 < sliderItems.length);
        },
        [sliderItems,]
    );

    // if items change => reset slider to first position
    useEffect(() => {
        setSliderPositionIndex(0);
    }, [items,]);

    console.log('DEBUGSLIDER', items);

    return (
        <div
            {...swipeHandlers}
            className={`${styles.slider} ${swipe && styles.swipe} ${className}`}
            style={{
                // @ts-ignore
                '--slider-transition-duration': `${transitionDuration}ms`,
            }}
        >
            <div
                className={styles.sliderRail}
                style={{
                    // @ts-ignore
                    '--slider-position-index': sliderPositionIndex,
                }}
            >
                {sliderItems.map((
                    sliderItem: any, index: number
                ) => {
                    return (
                        <div
                            key={index}
                            className={`${styles.sliderItem} ${
                                index === sliderPositionIndex && styles.sliderItemActive
                            }`}
                            data-items={itemsPerSlide}
                        >
                            {sliderItem}
                        </div>
                    );
                })}
            </div>
            <div className={displayNavigation ? '' : 'visibly-hidden'}>
                <button
                    className={`${styles.sliderControl} ${styles.prev} ${
                        0 === sliderPositionIndex && !rotate && styles.disabled
                    }`}
                    onClick={() => {
                        setSliderPositionIndex(calcSliderPositionIndex(-1));
                    }}
                >
                    <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M22.45 6.31005L20.68 4.55005L9.45996 15.77L20.68 26.99L22.45 25.22L12.99 15.77L22.45 6.31005Z"
                            fill="#1D1D1B"/>
                    </svg>
                </button>
                <button
                    className={`${styles.sliderControl} ${styles.next} ${
                        sliderItems.length - 1 <= sliderPositionIndex && !rotate && styles.disabled
                    }`}
                    onClick={() => {
                        setSliderPositionIndex(calcSliderPositionIndex(1));
                    }}
                >
                    <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M11.22 4.55005L9.45996 6.31005L18.91 15.77L9.45996 25.22L11.22 26.99L22.44 15.77L11.22 4.55005Z"
                            fill="#1D1D1B"/>
                    </svg>
                </button>
                <ul className={styles.sliderNavigation}>
                    {sliderNavigationItems.map((
                        item: number, index: number
                    ) => {
                        return (
                            <li
                                key={index}
                                className={`${styles.sliderNavigationItem} ${
                                    'white' === iconClass ? 'white-bg' : 'black-bg'
                                } ${index === sliderPositionIndex && styles.sliderNavigationItemActive}`}
                                onClick={() => {
                                    setSliderPositionIndex(index);
                                }}
                            >
                                {index}
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export default Slider;
