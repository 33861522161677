import * as React from 'react';
import {
    Link
} from 'gatsby';
// @ts-ignore
import * as styles from './CustomLink.module.css';

interface ICustomLink {
    to?: string,
    onClick?: any,
    label: string
    classNames?: string,
}

const CustomLink = ({
    to, label, classNames, onClick,
}: ICustomLink) => {
    if (onClick) {
        return <a
            onClick={onClick}
            className={styles[classNames]}>
            {label}
        </a>;
    }
    return <Link
        className={styles[classNames]}
        to={to}>{label}
    </Link>;
};

export default CustomLink;
