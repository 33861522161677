import * as React from 'react';
// @ts-ignore
import * as styles from './VoteBar.module.css';
import {
    useEffect,
    useState
} from 'react';
import DesktopVotingRow from '../DesktopVotingRow/DesktopVotingRow';
import MobileVotingRow from '../MobileVotingRow/MobileVotingRow';
import {connect} from 'react-redux';
import {apiGetAuthenticated} from '../../api/api';
import {classNames} from '../../helpers/classes';
import CustomButton from '../CustomButton/CustomButton';
import {getAuthUser, setAuthUser} from '../../helpers/user';
import {fetchUser} from '../../api/user';

interface IVoteBar {
    text: string,
}

export const votingCategories = [
    'Neuigkeitswert, Innovationsgrad und Ideenreichtum',
    'Erschließbares Marktpotential (Umsatz, Referenzkunden, Wettbewerber)',
    'Finanzielles Konzept und Stimmigkeit des Geschäftsplans (Wirtschaftlichkeit)',
    'Wachstumsorientierung',
    'Team (Erfahrung, Position)',
];

const VoteBar = ({
    juryVotings,
    setJuryVotings,
    text,
    application,
}: IVoteBar) => {
    const [isOpen, setIsOpen,] = useState(false);
    const [showHelp, setShowHelp,] = useState('true' !== localStorage.getItem('@focus:votebar.help.done'));
    const [user, setUser,] = useState(getAuthUser());

    useEffect(() => {
        (async () => {
            const user = await fetchUser();
            if (user) {
                setAuthUser(user);
                setUser(user);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            setJuryVotings({
                juryVotings: (await apiGetAuthenticated('/jury/votes')).votings,
            });
        })();
    }, []);

    // show help if user first enters this screen
    useEffect(() => {
        if (showHelp) {
        }
    }, []);

    if (user?.declared_independence) {
        return null;
    }

    return <><div className={classNames({
        [styles.container]: true,
        [styles.isOpen]: isOpen,
    })}>
        {/* Content */}
        <div
            onClick={() => {
                return setIsOpen(!isOpen);
            }}
            className={styles.content}>
            {/* Content Span */}
            <span className={styles.contentSpan}>{text}</span>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15.874"
                height="9.802"
                viewBox="0 0 15.874 9.802">
                <path
                    id="Path_534"
                    data-name="Path 534"
                    d="M7.865,8.59l6.072,6.059L20.009,8.59l1.865,1.865-7.937,7.937L6,10.455Z"
                    transform="translate(-6 -8.59)"
                    fill="#fff"/>
            </svg>
        </div>

        {/* Table */}
        <div className={styles.table}>
            {
                votingCategories.map(category => {
                    const storedVoting = juryVotings
                        .filter(v => {
                            return v.category === category && v.applicationUuid === application.uuid;
                        })
                        .pop();

                    return <div
                        key={category}
                        className={`${styles.tableContent} grid-12--desktop`}>
                        {/* Black Box Title */}
                        <div className={`${styles.blackBoxListTitle}`}>
                            {category}
                        </div>
                        {/* Black Box List Container */}
                        <div className={`${styles.blackBoxListContainer}`}>
                            <MobileVotingRow
                                category={category}
                                application={application}
                                storedVoting={storedVoting ? storedVoting.voting : null}
                            />
                            <DesktopVotingRow
                                category={category}
                                application={application}
                                storedVoting={storedVoting ? storedVoting.voting : null}
                            />
                        </div>
                    </div>;
                })
            }
            <div className={styles.legend}>* Bewertung von 1-10 wobei 10 die beste Wertung ist</div>
        </div>
    </div>


    {
        showHelp &&
        <div className={styles.help}>
            <div className={styles.helpCircle}>

                <div className={styles.helpContent}>
                    <span>Zur Stimmabgabe bitte aufklappen</span>
                    <CustomButton
                        label="Alles klar"
                        onClick={() => {
                            localStorage.setItem('@focus:votebar.help.done', 'true');
                            setShowHelp(false);
                        }}
                    />
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="127.739"
                        height="130.316"
                        viewBox="0 0 127.739 130.316">
                        <g
                            id="Group_1628"
                            transform="translate(-99.384 -392.301)">
                            <path
                                id="Path_535"
                                d="M-2029.57,520.5c137.761-29.562,123.745-126.5,123.745-126.5"
                                transform="translate(2131)"
                                fill="none"
                                stroke="#fff"
                                stroke-linecap="round"
                                stroke-width="3"/>
                            <line
                                id="Line_183"
                                x1="47"
                                y2="41"
                                transform="translate(101.5 479.5)"
                                fill="none"
                                stroke="#fff"
                                stroke-linecap="round"
                                stroke-width="3"/>
                            <line
                                id="Line_184"
                                x2="64"
                                transform="translate(102.5 520.5)"
                                fill="none"
                                stroke="#fff"
                                stroke-linecap="round"
                                stroke-width="3"/>
                        </g>
                    </svg>

                </div>

            </div>
        </div>
    }

    </>;
};


const mapStateToProps = ({
    juryVotings,
}) => {
    return {
        juryVotings,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setJuryVotings: ({
            juryVotings,
        }) => {
            return dispatch({
                type: 'SET_JURY_VOTINGS',
                payload: {
                    juryVotings,
                },
            });
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VoteBar);
