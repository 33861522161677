export const salutationKeyToLabel = (key: string) => {
    switch (key) {
        case 'm':
            return 'Herr';
        case 'w':
            return 'Frau';
        case 'd':
            return '';
        default:
            return key;
    }
};
