import * as React from 'react';
// @ts-ignore
import * as styles from './SubtitleAndParagraph.module.css';

interface ISubtitleAndParagraph {
    span: string,
    paragraph: string,
}

const SubtitleAndParagraph = ({
    span,
    paragraph,
}:ISubtitleAndParagraph ) => {
    return <div className={styles.container}>
        <span className={styles.span}>{ span }</span>
        <p className={styles.paragraph}>
            { paragraph }
        </p>
    </div>;
};

export default SubtitleAndParagraph;