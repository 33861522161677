import * as React from 'react';

// @ts-ignore
import * as styles from './CustomButton.module.css';
import {
    Link
} from 'gatsby';

interface ICustomLink {
    style?: 'button' | 'link'
    label: string
    additionalClassName?: string
    onClick?: () => void
    disabled?: boolean
    testId?: string,
    isLink?: boolean;
    to?: string
    target?: '_blank';
    className?: string;
}

const CustomButton = ({
    style = 'button',
    label,
    additionalClassName,
    className = '',
    onClick,
    disabled,
    testId,
    isLink,
    to,
    target,
}: ICustomLink) => {
    if (isLink) {
        if ('_blank' === target) {
            return <a
                className={`${styles[style]} ${styles[additionalClassName]} ${disabled ? styles.disabled: ''} ${className}`}
                data-test-id={testId}
                href={to}
                target={target}
            >{label}
            </a>;
        }
        return <Link
            className={`${styles[style]} ${styles[additionalClassName]} ${disabled ? styles.disabled: ''} ${className}`}
            data-test-id={testId}
            to={to}
            target={target}
        >{label}
        </Link>;
    }
    return <button
        className={`${styles[style]} ${styles[additionalClassName]} ${disabled ? styles.disabled: ''} ${className}`}
        type="button"
        onClick={onClick}
        data-test-id={testId}
    >{label}
    </button>;
};

export default CustomButton;
