import * as React from 'react';
// @ts-ignore
import * as styles from './MobileVotingRow.module.css';
import {connect} from 'react-redux';
import {apiPostAuthenticated} from '../../api/api';

const MobileVotingRow = ({category, application, storedVoting, setJuryVotings,}) => {
    return <select
        className={styles.mobileVotingRow__container}
        value={String(storedVoting)}
        onChange={(e) => {
            (async () => {
                const result = await apiPostAuthenticated(
                    `/jury/vote/${application.uuid}`,
                    {
                        category,
                        voting: parseInt(e.currentTarget.value, 10),
                    }
                );
                setJuryVotings({
                    juryVotings: result.votings,
                });
            })();
        }}
    >
        <option
            className={styles.mobilVotingRow__options}
            value="0"> - / 10
        </option>
        <option
            className={styles.mobilVotingRow__options}
            value="1">1/ 10
        </option>
        <option
            className={styles.mobilVotingRow__options}
            value="2">2/ 10
        </option>
        <option
            className={styles.mobilVotingRow__options}
            value="3">3/ 10
        </option>
        <option
            className={styles.mobilVotingRow__options}
            value="4">4/ 10
        </option>
        <option
            className={styles.mobilVotingRow__options}
            value="5">5/ 10
        </option>
        <option
            className={styles.mobilVotingRow__options}
            value="6">6/ 10
        </option>
        <option
            className={styles.mobilVotingRow__options}
            value="7">7/ 10
        </option>
        <option
            className={styles.mobilVotingRow__options}
            value="8">8/ 10
        </option>
        <option
            className={styles.mobilVotingRow__options}
            value="9">9/ 10
        </option>
        <option
            className={styles.mobilVotingRow__options}
            value="10">10/ 10
        </option>
    </select>;
};

const mapDispatchToProps = (dispatch) => {
    return {
        setJuryVotings: ({
            juryVotings,
        }) => {
            return dispatch({
                type: 'SET_JURY_VOTINGS',
                payload: {
                    juryVotings,
                },
            });
        },
    };
};

export default connect(
    null,
    mapDispatchToProps
)(MobileVotingRow);
