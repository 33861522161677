// extracted by mini-css-extract-plugin
export var container = "VoteBar-module--container--DT7_U";
export var legend = "VoteBar-module--legend--fG-Ip";
export var isOpen = "VoteBar-module--isOpen--SgMgr";
export var content = "VoteBar-module--content--2GmTp";
export var contentSpan = "VoteBar-module--contentSpan--lBmEU";
export var table = "VoteBar-module--table--1Q0JG";
export var tableContent = "VoteBar-module--tableContent--2AavH";
export var blackBoxListTitle = "VoteBar-module--blackBoxListTitle--1tjF1";
export var help = "VoteBar-module--help--2XB8B";
export var helpCircle = "VoteBar-module--helpCircle--Y2srA";
export var helpContent = "VoteBar-module--helpContent--3kh1-";
export var blackBoxListContainer = "VoteBar-module--blackBoxListContainer--2xcQk";