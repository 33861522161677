export const booleanToLabel = (value: string) => {
    switch(value) {
        case 'n':
            return 'Nein';
        case 'j':
        case 'y':
            return 'Ja';
        default:
            return value;
    }
};
