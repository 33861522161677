export const formatDateFromString = (dateString?: string) => {
    if (!dateString) {
        return '';
    }
    if (!dateString.match(/[0-9]{4}-[0-9]{2}-[0-9]{2}/)) {
        return dateString;
    }
    const parts = dateString.split('-');
    const date = new Date();
    date.setFullYear(parseInt(parts[0], 10));
    date.setMonth(parseInt(parts[1], 10) - 1);
    date.setDate(parseInt(parts[2], 10));
    return `${String(date.getDate()).padStart(2, '0')}.${String(date.getMonth() + 1).padStart(
        2,
        '0'
    )}.${date.getFullYear()}`;
};

export const formatDate = (date: Date) => {
    if (null === date) {
        return null;
    }
    return `${String(date.getDate()).padStart(2, '0')}.${String(date.getMonth() + 1).padStart(
        2,
        '0'
    )}.${date.getFullYear()}`;
};

export const formatDateForMySQL = (date: Date) => {
    if (null === date) {
        return null;
    }
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(
        2,
        '0'
    )}`;
};
